<template>
  <div id="app">
    <transition>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    let userInfo = localStorage.getItem("userInfo") || "{}";
    !!userInfo && (userInfo = JSON.parse(userInfo));

    let postToken = localStorage.getItem("postToken") || "{}";
    !!postToken && (postToken = JSON.parse(postToken));

    this.$store.replaceState({
      userStore: {
        ...this.$store.state.userStore,
        userInfo,
        postToken,
      },
    });

    console.log({ ...this.$store.state.userStore, userInfo, postToken });
  },
};
</script>

<style>
* {
  font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif;
  padding: 0;
  margin: 0;
}

html,
body,
#app {
  height: 100vh;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: fixed;
  right: 0;
  top: 0;
}
::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.3);
  /* background-color: yellow; */
}
::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.1);
  /* background-color: red; */
}
</style>
