import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
    { path: '/', redirect: '/home' },
    { path: '/login', component: () => import('@/pages/Login.vue'), meta: { title: '登录' } },
    {
        path: '/index',
        redirect: '/home',
        component: () => import('@/pages/Index.vue'),
        children: [
            {
                meta: { title: '首页' },
                path: '/home',
                component: () => import('@/pages/Home.vue')
            },
            {
                meta: { title: '文章详情' },
                path: '/detail/:id',
                component: () => import('@/pages/Card/Detail.vue')
            },
            {
                meta: { title: '账号信息' },
                path: '/account/:name/:id',
                component: () => import('@/pages/Account.vue')
            },
            {
                meta: { title: '关注信息' },
                path: '/follow/:name/:id/:type',
                component: () => import('@/pages/Follow.vue')
            },
            {
                meta: { title: '标签专题详情' },
                path: '/tag/:name/:id',
                component: () => import('@/pages/Tag/Detail.vue')
            }
        ]
    },
];

const mode = 'history';
const router = new VueRouter({ mode, routes });

// 注意使用beforeResolve 而非 beforeEach因为组件未挂载 所以取不到store
router.beforeResolve((to, from, next) => {
    const { userStore } = router.app.$options.store.state;
    const whiteList = ['/login'];
    // 检测是否登录
    const isLogin = !!userStore.userInfo && Object.keys(userStore.userInfo).length;
    if (isLogin || whiteList.includes(to.path)) {
        document.title = to.meta.title;
        return next();
    } else {
        return next('/login');
    }
});

export default router;