import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);

const userStore = {
    state: () => ({
        userInfo: {
        },
        postToken: {

        },
    }),
    // redux-action
    mutations: {
        setUserInfo(state, payload) {
            state.userInfo = payload;
            localStorage.setItem("userInfo", JSON.stringify(payload));
        },
        setPostToken(state, payload) {
            // 过期时间1小时
            const postToken = { token: payload, expire: Date.now() + 60 * 60 * 1 * 1000 };
            state.postToken = postToken;
            localStorage.setItem("postToken", JSON.stringify(postToken));
        }
    },
}
const store = new Vuex.Store({
    modules: {
        userStore
    }
});

export default store;
